import React, { useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import "./Feedback.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Button from '@mui/joy/Button';
import axios from "axios";
import IconButton from "@mui/joy/IconButton";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
  "& .MuiSvgIcon-root": {
    fontSize: "50px",
    marginRight: "10px",
  },
  "@media (max-width: 990px)": {
    fontSize: "25px",
    marginRight: "5px",
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return (
    <span className="icon" {...other}>
      {customIcons[value].icon}
    </span>
  );
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};



const Feedback = () => {
  const [isFeedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [platformFeedback, setPlatformFeedback] = useState(0);
  const [overallFeedback, setOverallFeedback] = useState(0);
  const [name, setName] = useState("");
  const [tcid, setTcid] = useState("");
  const [errname, seterrname] = useState(false);
  const [errtcid, seterrtcid] = useState(false);
  const [text, setText] = useState("");
  // const [loading, setLoading] = useState(false);
  const addEmoji = (emoji) => () => setText(`${text}${emoji}`);
  const namepattern = /^[A-Za-z]{2}[A-za-z ]{0,28}$/gm;
  const tcidpattern =/^TC\d{3}$/;

  const feedAPI = "https://tc-feedback.onrender.com/rating";
  

  const showToast = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const handleSubmitFeedback = (e) => {
    
    e.preventDefault();
  
    if (name.match(namepattern)) seterrname(false);
    if (tcid.match(tcidpattern)) seterrtcid(false);
    
    if (
      name.length === 0 ||
      tcid.length === 0 ||
      text.length === 0
    ) {
      showToast("Please Fill all the Entries");
    } else if (!name.match(namepattern)) {
      seterrname(true);
      showToast("Please Enter a Valid Name");
    } else if (!tcid.match(tcidpattern)) {
      seterrtcid(true);
    }
    else {
      
      // setLoading(true);
      e.preventDefault();
      axios
        .post(feedAPI, {
          name: name,
          tcid: tcid,
          text: text,
          rating1: Number(platformFeedback),
          rating2: Number(overallFeedback)
        })
        .then((result) => {
          setFeedbackSubmitted(true);
          // setLoading(false);
          toast.success(`Thankyou for your feedback`);
          // console.log(result);
        })
        .catch((err) => {
          setFeedbackSubmitted(true);
          // console.log(err);
          toast.success(`Thankyou for your feedback`);
          // setLoading(false);
          
        });
    }
  }

  return (
    
    <div className="feedback_page">
       <ToastContainer
          position="top-right"
          autoClose={4000}
          limit={5}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      <div className="Head_feed">Feedback</div>

{!isFeedbackSubmitted? <>
      <div className="feedback">
        <div className="feedback_form">
          <div className="feedback_entry">
            <Box
              component="form"
              sx={{
                "& > :not(style)": {
                  margin: "0 50px 15px 0",
                  width: "40ch",
                  
                  "@media (max-width: 1200px)": {
                    margin: "0 40px 10px 0",
                    width: "35ch"
                  },
                  "@media (max-width: 990px)": {
                    width: "25ch",
                    margin: "0 30px 7px 0",
                  },
                  "@media (max-width: 775px)": {
                    width: "30ch",
                    display: "flex",
                  flexDirection: "column",
                  },
                  "@media (max-width: 475px)": {
                    width: "auto",
                  },
                  // "@media (max-width: 320px)": {
                  //   width: "26ch",
                  // },
                  // "@media (max-width: 300px)": {
                  //   width: "23ch",
                  // },
                },
              }}
              noValidate
              autoComplete="off"
              className="input"
            >
              <TextField
                required
                id="standard-required"
                label="Full Name"
                variant="standard"
                type="name"
                error={errname}
                helperText={errname ? "Enter Correct Name" : ""}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  const namepattern = /^[A-Za-z]{2}[A-za-z ]{0,28}$/gm;
                  seterrname(!namepattern.test(e.target.value));
                }}
              />
              <TextField
                required
                id="standard-required"
                label="TC ID"
                variant="standard"
                type="name"
                error={errtcid}
                helperText={errtcid ? "Enter Correct TC ID" : ""}
                value={tcid}
                onChange={(e) => {
                  setTcid(e.target.value.toUpperCase());

                  const tcidpattern =
                    /^TC\d{3}$/;
                  seterrtcid(!tcidpattern.test(e.target.value));
                }}
              />
            </Box>
          </div>
          <div id="txtar">
          <Textarea
  placeholder="Let us know your thoughts..."
  value={text}
  onChange={(event) => setText(event.target.value)}
  minRows={2}
  startDecorator={
    <Box sx={{ display: "flex", gap: 0.5, flex: 1 }}>
      <IconButton
        variant="outlined"
        color="neutral"
        sx={{border: "1px solid rgba(136, 112, 223, 0.27)",}}
        onClick={addEmoji("👍")}
      >
        👍
      </IconButton>
      <IconButton
        variant="outlined"
        color="neutral"
        sx={{border: "1px solid rgba(136, 112, 223, 0.27)",}}
        onClick={addEmoji("🏖")}
      >
        🏖
      </IconButton>
      <IconButton
        variant="outlined"
        color="neutral"
        sx={{border: "1px solid rgba(136, 112, 223, 0.27)",}}
        onClick={addEmoji("😍")}
      >
        😍
      </IconButton>
    </Box>
  }
  endDecorator={
    <Typography level="body-xs" sx={{ ml: "auto" }}>
      {text.length} character(s)
    </Typography>
  }
  sx={{
    minWidth: 300,
    backgroundColor: "rgba(124, 100, 210, 0.267)", 
    border: "1px solid #440d68",
    "@media (max-width: 485px)": { width: "90%" }
  }}
/>
</div>

        </div>
        <div className="f1" id="fm1">
          <div className="cnt1">
            <h3 className="h">Platform Feedback</h3>
            <p className="ps">
              (How smooth and intuitive was your experience with our Website?)
            </p>
          </div>
          <StyledRating
            name="platform-feedback"
            value={platformFeedback}
            IconContainerComponent={IconContainer}
            getLabelText={(value) => customIcons[value].label}
            highlightSelectedOnly
            onChange={(event, newValue) => {
              setPlatformFeedback(newValue === platformFeedback ? 0 : newValue);
            }}
          />
        </div>
        <div className="f1" id="fm2">
          <div className="cnt1">
            <h3 className="h">Overall Experience</h3>
            <p className="ps">
              (Your feedback is valuable - Please provide your input)
            </p>
          </div>
          <StyledRating
            name="questions-feedback"
            value={overallFeedback}
            IconContainerComponent={IconContainer}
            getLabelText={(value) => customIcons[value].label}
            highlightSelectedOnly
            onChange={(event, newValue) => {
              setOverallFeedback(newValue === overallFeedback ? 0 : newValue);
            }}
          />
        </div>
      </div>
      {!isFeedbackSubmitted && (
        <button
          className="submit-feedback-button"
          onClick={handleSubmitFeedback}
          disabled={!platformFeedback|| !overallFeedback}
        >
          Submit Feedback
        </button>
      )}
      </>:<>
       <div className="feed_done">
          <BsCheck2Circle className="check-icon" />
          <h2 style={{ fontSize: "28px" , textAlign:  'center'}}>You have successfully submitted the feedback</h2>
          <p className="feedback-submitted-message">
            Feedback submitted. Thank you!
          </p>
        </div></>}
    </div>
  );
};

export default Feedback;
