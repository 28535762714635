import React from "react";
import logo from "../assets/ConatusLogoRed.png";
import img1 from "../assets/Group 65.svg";
import img2 from "../assets/GroupA.png";
import "./About.css";
import spk1 from "../assets/kavachchandra.jpg";
import spk2 from "../assets/spk2.png";
import spk3 from "../assets/spk3.png";
import spk4 from "../assets/spk4.png";
import spk5 from "../assets/Vineetgupta.jpg";
import spk6 from "../assets/spk6.png";
import spk7 from "../assets/spk7.png";
import priyanshagarwal from "../assets/priyanshagarwal.jpg"
import CustomizedTimeline from "./CustomizedTimeline";
import { FacultyCard } from "./FacultyCard";
import EventPass from "./EventPass";

import Contact from "./Contact";

function About() {
  return (
    <>
      <div className="about_main">
        <div className="about_row1">
          <div className="about_row1_col1">
            <img alt="" src={img1} />
          </div>
          <span className="about_row1_col2">TECHNO CONCLAVE 2023</span>
          <div className="about_row1_col4">
            <img alt="" src={logo} />
          </div>
        </div>

        <div className="about_row2">
          <p>
            Embark on a visionary exploration at Team Conatus' Techno Conclave -
            a convergence of innovation and expertise. Engage with industry
            trailblazers in tech, design, and development through immersive
            talks and interactive sessions. Join us for a dynamic exchange of
            ideas, shaping the future of tomorrow's innovations.
          </p>
        </div>
        <div className="about_row3" id="ABOUT">
          <div className="about_row3_col1">
            <p>About the Event</p>
            Dive into the forefront of technology, design, and development—an
            immersive fusion of expertise, innovation, and inspiration uniting
            thought leaders, pioneers, and learners.
            <br></br>
            <br></br>
            <b>Perks of participation:</b>
            <ul>
              <li>
                Exclusive Insights: Gain specialized wisdom from seasoned
                professionals. Expand your understanding of the latest trends in
                tech and design.{" "}
              </li>
              <li>
                Immersive Learning: Attend four enlightening keynote sessions.
                Participate in two hands-on workshops focusing on design and the
                metaverse.{" "}
              </li>
              <li>
                Networking: Connect with like-minded individuals in a vibrant
                environment. Foster collaborations that transcend boundaries.
              </li>
            </ul>
          </div>
          <div className="about_row3_col2">
            <img alt="" src={img2} />
          </div>
        </div>

        <div className="about_row4">
          <div className="about_row4_col1">
            <p>Be inspired by Industry Leaders</p>
            Join us to gain inspiration from the trailblazers of various
            industries, sharing their insights and expertise in our workshop
            sessions. Explore, learn, and be empowered by industry leaders.
          </div>
          <div className="about_row4_col2">
          <div className="speaker_photo_container">
              <div className="imm">
                {" "}
                <img alt="" src={spk7} />
              </div>
              <span className="speaker_name">Abhishek Dubey </span>
              <span className="speaker_designation">IIT Delhi</span>
            </div>
            <div className="speaker_photo_container">
              <div className="imm">
                {" "}
                <img alt="" src={spk2} />
              </div>
              <span className="speaker_name">Shashank Jha </span>
              <span className="speaker_designation">Reslink, Founder</span>
            </div>
            <div className="speaker_photo_container">
              <div className="imm">
                {" "}
                <img alt="" src={spk1} />
              </div>
              <span className="speaker_name">Kavach Chandra</span>
              <span className="speaker_designation">FreeStand</span>
            </div>
            <div className="speaker_photo_container">
              <div className="imm">
                {" "}
                <img alt="" src={spk6} />
              </div>
              <span className="speaker_name">Gaurav Jain </span>
              <span className="speaker_designation">Cloudera</span>
            </div>

            <div className="speaker_photo_container">
              <div className="imm">
                {" "}
                <img alt="" src={spk5} />
              </div>
              <span className="speaker_name">Vineet Gupta </span>
              <span className="speaker_designation">Gemini Solutions</span>
            </div>
              
            <div className="speaker_photo_container">
              <div className="imm">
                {" "}
                <img alt="" src={spk4} />
              </div>
              <span className="speaker_name">Mayank Singh </span>
              <span className="speaker_designation">XR Central</span>
            </div>
            <div className="speaker_photo_container">
              <div className="imm">
                {" "}
                <img alt="" src={priyanshagarwal} />
              </div>
              <span className="speaker_name">Priyansh Agarwal </span>
              <span className="speaker_designation">TLE Eliminators, Founder </span>
            </div><div className="speaker_photo_container">
              <div className="imm">
                {" "}
                <img alt="" src={spk3} />
              </div>
              <span className="speaker_name">Tushar Gupta </span>
              <span className="speaker_designation">Google</span>
            </div>
          
            
          
            
            
          </div>
        </div>
        <div className="about_row5" id="SCHEDULE">
          <div className="about_row5_col1">
            <p>Event Schedule</p>
            Join us for an intellectually stimulating series of talks and
            discussions at Team Conatus' Techno Conclave. Our lineup of seasoned
            professionals and experts will delve into diverse domains, offering
            insights and sparking conversations on cutting-edge topics in
            technology, design, and development.
          </div>
          <CustomizedTimeline />
        </div>
<EventPass/>
        <div className="faculty_det" id="FACULTY">
          <div className="faculty_row1">
            <p>Faculty Coordinators</p>
          </div>
          <div className="faculty_div">
            <FacultyCard />
          </div>
        </div>
        <div id="CONTACT">
        <Contact />
        </div>
      </div>
    </>
  );
}

export default About;
