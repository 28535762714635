import React from "react";
import "./Header.css";
import Navbar from "../components/Navbar";
import B1 from "../assets/Group 113.svg";
import B2 from "../assets/Group 135.svg";
import logo from "../assets/Group 92.svg";
import Conatus from "../assets/ConatusLogo.png";
import { IoCalendarClear } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import Code from '../assets/Group 92.png';
import Particles from "../components/Particles";
import { useNavigate } from "react-router-dom";
export const Header = () => {
    const navigate = useNavigate();
    function reg(){
        navigate("/register");
    }
  return (
    <>
    <Navbar />
    <div className="header">
        <Particles id="tsparticles" />
        <div className="hero">
          <div className="row1">
            <img src={B1} alt="Bracket" className="bracs"/>
            <span className="title">
              <img src={logo} alt="Logo" className="log" />
              <p className="ev_name">TECHNO CONCLAVE 2023</p>
              <p className="x">X</p>
              <img
                src={Conatus}
                alt="Conatus"
                className="conatuslogo"
              ></img>
            </span>
            <img src={B2} alt="Bracket" className="bracs"/>
          </div>
          <div className="row2">
            <span className="col1">
              <IoCalendarClear className="cal" style={{ color: "white" }} />
              <p className="date">2 December - 7 December</p>
            </span>
            <span className="col2">
              <FaLocationDot className="cal" style={{ color: "white" }} />
              <p className="date">CS/IT Seminar Hall</p>
            </span>
          </div>
          <div className="row3">
            <p className="lgn">Learn.Improvise.Grow</p>
          </div>
          
          <div className='submit-button'>
      <button className="ui-btn" onClick={reg}>
  <span>
    Register Now
  </span>
</button>
      </div>
          <img className="bottom-img" src={Code} alt="Code"/>
          </div>
      

    </div>
    </>
  );
};
