import * as React from "react";
import "react-toastify/dist/ReactToastify.css";
import "./Form.css";
import logo from "../assets/ConatusLogoRed.png";
import img1 from "../assets/Group 65.svg";
import Timer from "../components/comingsoon/Timer";

function Form() {
  return (
    <>
      <div className="container">
        <div className="form_main">
          <div className="form_row1">
            <div className="form_row1_col1">
              <img alt="elements" src={img1} />
            </div>
            <span className="form_row1_col2">TECHNO CONCLAVE 2023</span>
            <div className="form_row1_col4">
              <img alt="elements" src={logo} />
            </div>
          </div>

          <span className="Heading">Registration</span>

          {/* //REGISTRATION PAGE COMING SOON */}

          <div className="reg_soon">
            <div className="reg_soon_1">
            {/* <span className="soon_head">Coming Soon!</span> */}
            <div 
                    className="soon_head">
                      Registrations are closed!
                    </div>
            <Timer/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;