import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AndroidIcon from '@mui/icons-material/Android';
import DrawIcon from '@mui/icons-material/Draw';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import GoogleIcon from '@mui/icons-material/Google';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Typography from '@mui/material/Typography';

export default function CustomizedTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        > <span style={{ color: 'black' }}>2 December</span> <br></br>
        <span>9am - 3pm(Full Day)</span>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <CloudQueueIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '32px', px: 2 }}>
          <Typography variant="h6" component="span">
            Day 1
          </Typography>
          <Typography>Workshop</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          <span style={{ color: 'black' }}>4 December</span> <br></br>
        <span> 4-7 pm</span>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <AndroidIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '32px', px: 2 }}>
          <Typography variant="h6" component="span">
           Day 2</Typography>
          <Typography>Speaker 1 + Speaker 2</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          <span style={{ color: 'black' }}>5 December</span> <br></br>
        <span> 4-7 pm</span>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <DrawIcon />
          </TimelineDot>
          <TimelineConnector  />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '32px', px: 2 }}>
          <Typography variant="h6" component="span">
            Day 3
          </Typography>
          <Typography>Speaker 3 + Speaker 4</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          <span style={{ color: 'black' }}>6 December</span> <br></br>
        <span> 4-7 pm</span>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <LightbulbIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '32px', px: 2 }}>
          <Typography variant="h6" component="span">
            Day 4
          </Typography>
          <Typography>Speaker 5</Typography>
        </TimelineContent>
      </TimelineItem><TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
        <span style={{ color: 'black' }}>7 December</span> <br></br>
        <span> 4-7 pm</span>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <GoogleIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '32px', px: 2 }}>
          <Typography variant="h6" component="span">
            Day 5
          </Typography>
          <Typography>Online Session + Speaker 6</Typography>
        </TimelineContent>
      </TimelineItem>
      
    </Timeline>
  );
}