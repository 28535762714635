import './App.css';
import RegisterPage from './components/RegisterPage';
import { Home } from './components/Home';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Teams from './components/Teams';
import FeedbackPage  from './components/FeedbackPage';

function App() {
  return (
    <>
    
    <BrowserRouter>
      <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/register" element={< RegisterPage/>} />
            <Route path="/team" element={<Teams/>}/>
            <Route path="/feedback" element={<FeedbackPage/>}/>
            
    </Routes>
    </BrowserRouter>
    
    </>
  );
}

export default App;
