import React from 'react'
import { Header } from './Header'
import About from './About'
import Footer from './Footer'
export const Home = () => {
  return (
    <>
    <Header/>
    <About/>
    <Footer/>
    </>
  )
}
