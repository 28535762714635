import React from "react";
import "./Footer.css";
import img1 from "../assets/Group 92.svg";
import img2 from "../assets/ConatusLogo.png";
import { CiGlobe } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="footer_container">
          <div className="footer_row1">
            <div className="footer_row1_col1">
              {" "}
              <img src={img1} alt="" />{" "}
            </div>
            <span className="footer_row1_col2">TECHNO CONCLAVE 2023</span>
            <div className="footer_row1_col3">X</div>
            <div className="footer_row1_col4">
              <img src={img2} alt="" />
            </div>
          </div>
          <div className="footer_row2">
            <span>
              Ajay Kumar Garg Engineering College, 27th Km milestone NH-24
              Delhi-Hapur Bypass, Adhyatmik Nagar Ghaziabad.
            </span>
          </div>
          <div className="footer_row3">
            <span>
              <p>
                If you require help or have any inquiries, feel free to reach
                out - we're here to support you. CSE Lab 4, 4th floor CSIT Block
              </p>
              <br></br>
              <div style={{display:"flex",alignItems:"end"}}>
                <p>Contact: </p>
                <span>
                  <p className="no">+91 9259249507,</p>
                  <p className="no"> +91 7880915437</p>{" "}
                </span>
              </div>
            </span>
          </div>
          <div className="footer_row4">
            <a href="https://teamconatus.com/">
              <CiGlobe style={{ fontSize: "31px", margin: "0 10px" }} />
            </a>
            <a href="https://www.linkedin.com/company/team-conatus/">
              <FaLinkedin style={{ fontSize: "30px", margin: "0 10px" }} />
            </a>
            <a href="https://www.instagram.com/conatus.akg/">
              <FaInstagram style={{ fontSize: "30px", margin: "0 10px" }} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
