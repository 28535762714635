import React from "react";
import "./FacultyCard.css";
import hod from "../assets/anuchaudhary.png";
import shashank_sir from "../assets/shashanksahu.png";
import manish_sir from "../assets/Ellipse 27.png";

export const FacultyCard = () => {
  return (
    <>
      <div className="card-contain">
        <div className="faccard">
          <div className="front-content">
            <div className="faculty_img">
              <img alt="" src={hod} />
            </div>
            <span className="faculty_name">Dr. Anu Chaudhary</span>
            <span className="faculty_designation">H.O.D</span>
            <div className="bottom_col"></div>
          </div>

          <div className="content">
            <div className="faculty_bimg">
              <img alt="" src={hod} />
            </div>
            <p className="heading">Dr. Anu Chaudhary</p>
            <p>
              Dr. Anu Chaudhary,Professor and Head of the Computer Science and
              Engineering, with 22 years of expertise, specializes in high-speed
              data networks, contributing 20+ papers, 6 patents, and active
              academic leadership.
            </p>
          </div>
        </div>
      </div>
      <div className="card-contain">
        <div className="faccard">
          <div className="front-content">
            <div className="faculty_img">
              <img alt="" src={shashank_sir} />
            </div>
            <span className="faculty_name">Dr. Shashank Sahu</span>
            <span className="faculty_designation">Professor</span>
            <div className="bottom_col"></div>
          </div>
          <div className="content">
            <div className="faculty_bimg">
              <img alt="" src={shashank_sir} />
            </div>
            <p className="heading">Dr. Shashank Sahu</p>
            <p>
              Professor Shashank Sahu, a seasoned educator with 25 years in
              Computer Science, emphasizes teaching and boasts 19 international
              journal publications. He guides PhD research at Amity University,
              and is an active IEEE member, committed to advancing computer
              science.
            </p>
          </div>
        </div>
      </div>
      <div className="card-contain">
        <div className="faccard">
          <div className="front-content">
            <div className="faculty_img">
              <img alt="" src={manish_sir} />
            </div>
            <span className="faculty_name">Mr. Manish Kumar</span>
            <span className="faculty_designation">Assistant Professor</span>
            <div className="bottom_col"></div>
          </div>
          <div className="content">
            <div className="faculty_bimg">
              <img alt="" src={manish_sir} />
            </div>
            <p className="heading">Mr. Manish Kumar</p>
            <p>
              Mr. Manish Kumar, an Assistant Professor in the Department of
              Computer Science & Engineering, has 19 years of teaching
              experience and holds a Bachelor's and Master's degree in Computer
              Science, demonstrating a robust academic background and expertise
              in the field.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
