import React from "react";
import "./EventPass.css";
import pass from "../assets/Pass.svg";

function EventPass() {
  return (
    <>
      <div className="event_pass">
        <div className="pass_row1">
          <p>Event Pass</p>
          Secure your access to an inspiring journey through innovation and
          knowledge at Conatus' Techno Concalave! Our event pass grants you
          exclusive entry to engaging talks, enlightening discussions, and
          networking opportunities with industry experts and enthusiasts.
        </div>
        <div className="pass_row2">
          <div className="pass_img">
            <img src={pass} alt="EventPass" />
          </div>
        </div>
      </div>
    </>
  );
}

export default EventPass;
