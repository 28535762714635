import React from "react";
import "./Contact.css";
import { useForm, ValidationError } from "@formspree/react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsFillTelephoneInboundFill } from "react-icons/bs";

import { SiMinutemailer } from "react-icons/si";

const Contact = () => {
  const [state, handleSubmit] = useForm("mvojjzqj");

  return (
    <div id="cnpd">
      <h2 className="contact_head">Contact Us</h2>
      <div className="contact_container">
        <div className="content">
          <div className="left-side">
            <div className="address details">
              <div className="contact_icon">
                <FaMapMarkerAlt />
              </div>
              <div className="topic">Address</div>
              <div className="text-one">AKGEC, Ghaziabad</div>
              <div className="text-two">Delhi-Meerut Expressway</div>
            </div>
            <div className="phone details">
              <div className="contact_icon">
                <BsFillTelephoneInboundFill />
              </div>
              <div className="topic">Phone</div>
              <div className="text-one">+91 9259249507</div>
              <div className="text-two">+91 7880915437</div>
            </div>
            <div className="email details">
              <div className="contact_icon">
                <SiMinutemailer />
              </div>
              <div className="topic">Email</div>
              <div className="text-one">teamconatus@gmail.com</div>
              {/* <div className="text-two">info@gmail.com</div> */}
            </div>
          </div>
          
            {state.succeeded ? (
              <div className="thanks_text">
              <p className="contact_submit">Thanks for joining!</p>
              </div>
            ) : (
              <div className="right-side">
                <div className="topic-text">Send us a message</div>

                <form onSubmit={handleSubmit}>
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="Enter your name"
                      name="Username"
                    />
                  </div>
                  <ValidationError
                    prefix="Username"
                    field="name"
                    errors={state.errors}
                  />

                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="Enter your email"
                      name="Email"
                    />
                  </div>
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />

                  <div className="input-box message-box">
                    <textarea
                      type="text"
                      placeholder="Leave us a message ..."
                      name="Message"
                    ></textarea>
                  </div>
                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                  />

                  <div className="button">
                    <button
                      type="submit"
                      className="send_button"
                      disabled={state.submitting}
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            )}
          
        </div>
      </div>
    </div>
  );
};

export default Contact;
