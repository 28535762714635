import React from "react";
import "./Teams.css";
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import praveen from "../assets/praveen21.jpg";
import priyanshi from "../assets/priyanshi.jpg";
import annanay from "../assets/Annanay.jpg";
import shorya from "../assets/Shorya.jpg";
import sneha from "../assets/snehajaiswal.png";
import FooterDev from "./FooterDev";
import Navbar from "./Navbar";

function Teams() {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {}, []);
  return (
    <> <Navbar/>
      <div className="team_main">
      <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: "#020221",
              },
            },
            fullScreen: {
              enable: true,
              zIndex: -1,
            },
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 1,
                },
              },
            },
            particles: {
              color: {
                value: "#a852e5",
              },
              links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.3,
                width: 1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 1000,
                },
                value: 80,
              },
              opacity: {
                value: 0.9,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
          }}
        />
       
        <div className="dev">
          <p className="head_team">Development Team</p>
          <div className="card_main">
            <div className="col-md-3 col-sm-3" style={{ margin: "30px" }}>
              <div className="dev-team">
                <div className="pic">
                  <img src={praveen} alt="Profile" />
                </div>
                <div className="dev-content">
                  <h5 className="dev-title">Praveen Kushwaha</h5>
                  <span className="dev-post">
                    Web Developer
                    <br />
                  </span>
                </div>
                <ul className="dev-social">
                  <li>
                    <a
                      href="https://www.instagram.com/kushwaha.praveen_/"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><i className="fab fa-instagram"></i></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/praveen-kushwaha-a5092925b/"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><i className="fab fa-linkedin"></i></a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/KushwahaPraveen1"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><i className="fab fa-github"></i></a>
                  </li>
                  <li>
                    <a
                      href="mailto:kushwahapraveen0507@gmail.com"
                    
                      target="_blank"
                      rel="noopener noreferrer"><i className="fa fa-envelope"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-3" style={{  margin: "30px"}}>
              <div className="dev-team">
                <div className="pic">
                  <img src={priyanshi} alt="Profile" />
                </div>
                <div className="dev-content">
                  <h5 className="dev-title">Priyanshi Varyani</h5>
                  <span className="dev-post">
                    Web Developer
                    <br />
                  </span>
                </div>
                <ul className="dev-social">
                  <li>
                    <a
                      href="https://www.instagram.com/priyanshi.varyani/"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><i className="fab fa-instagram"></i></a>
                  </li>
                  <li>
                    <a
                      href="www.linkedin.com/in/priyanshi-varyani"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><i className="fab fa-linkedin"></i></a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/Priyanshi2018"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><i className="fab fa-github"></i></a>
                  </li>
                  <li>
                    <a
                      href="mailto:varyanipriyanshi@gmail.com"
                      
                      target="_blank"
                      rel="noopener noreferrer"><i className="fa fa-envelope"></i></a>
                  </li>
                </ul>
              </div>
            </div>{" "}
            <div className="col-md-3 col-sm-3" style={{ margin: "30px" }}>
              <div className="dev-team">
                <div className="pic">
                  <img src={shorya} alt="Profile" />
                </div>
                <div className="dev-content">
                  <h5 className="dev-title">Shorya</h5>
                  <span className="dev-post">
                    Web Developer
                    <br />
                  </span>
                </div>
                <ul className="dev-social">
                  <li>
                    <a
                      href="https://www.instagram.com/shorya_saini_7/"
                      target="_blank"
                      rel="noopener noreferrer"
                     ><i className="fab fa-instagram"></i></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/shorya-9b8b50241/"
                      target="_blank"
                      rel="noopener noreferrer"
                     ><i className="fab fa-linkedin"></i></a>
                  </li>
                  <li>
                    <a
                     href="https://github.com/Shorya7"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><i className="fab fa-github"></i></a>
                  </li>
                  <li>
                    <a
                      href="mailto:shorya8990@gmail.com"
                     
                      target="_blank"
                      rel="noopener noreferrer"><i className="fa fa-envelope"></i></a>
                  </li>
                </ul>
              </div>
            </div>{" "}
          </div>{" "}
          <div className="card_main">
            <div className="col-md-3 col-sm-3" style={{ margin: "30px" }}>
              <div className="dev-team">
                <div className="pic">
                  <img src={sneha} alt="Profile" />
                </div>
                <div className="dev-content">
                  <h5 className="dev-title">Sneha Jaiswal</h5>
                  <span className="dev-post">
                    Web Developer
                    <br />
                  </span>
                </div>
                <ul className="dev-social">
                  <li>
                    <a
                      href="https://www.instagram.com/snehha_27/"
                      target="_blank"
                      rel="noopener noreferrer"
                     ><i className="fab fa-instagram"></i></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/sneha-jaiswal-3664b6237/"
                      target="_blank"
                      rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/SnehaJaiswal123"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><i className="fab fa-github"></i></a>
                  </li>
                  <li>
                    <a
                      href="mailto:snehajais270703@gmail.com"
                      
                      target="_blank"
                      rel="noopener noreferrer"><i className="fa fa-envelope"></i></a>
                  </li>
                </ul>
              </div>
            </div>{" "}
            <div className="col-md-3 col-sm-3" style={{ margin: "30px" }}>
              <div className="dev-team">
                <div className="pic">
                  <img src={annanay} alt="Profile" />
                </div>
                <div className="dev-content">
                  <h5 className="dev-title">Annanay Aggarwal</h5>
                  <span className="dev-post">
                    Web Developer
                    <br />
                  </span>
                </div>
                <ul className="dev-social">
                  <li>
                    <a
                      href="https://instagram.com/annanayaggarwal?igshid=OGQ5ZDc2ODk2ZA=="
                      target="_blank"
                      rel="noopener noreferrer"
                      ><i className="fab fa-instagram"></i></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/annanay-aggarwal-3bb224224"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><i className="fab fa-linkedin"></i></a>
                  </li>
                  <li>
                    <a
                      href="http://github.com/annanayaggarwal"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><i className="fab fa-github"></i></a>
                  </li>
                  <li>
                    <a
                      href="mailto:anni.agg2003@gmail.com"
                      
                      target="_blank"
                      rel="noopener noreferrer"><i className="fa fa-envelope"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterDev />
    </>
  );
}

export default Teams;