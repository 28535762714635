import React, {useState} from 'react';
import './Navbar.css'; 
import {Link, useNavigate} from "react-router-dom";
import NavLogo from "../assets/Group 92.svg";

const Navbar = () => {
  const navigate = useNavigate();
  function redirectHome(){
    navigate("/");
  }

  const [isMobile, setIsMobile] = useState(false);
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMobile(false); // Close the mobile menu after clicking on a link
  };

    return (
        <nav className="navbar">
        <img alt="Logo" className='nav_logo' src={NavLogo} onClick={redirectHome}/>
        <ul style={{margin:'0', padding:'0'}} className={isMobile? "nav-links-mobile":"nav-links"}
        onClick={()=>setIsMobile(false)}
        >
          <Link to="/" className="about">
            <li onClick={() => scrollToSection('ABOUT')}>About</li>
          </Link>
          
          <Link to="/" className="schedule">
            <li onClick={() => scrollToSection('SCHEDULE')}>Schedule</li>
          </Link>
          {/* <Link to="/" className="faculty">
            <li onClick={() => scrollToSection('FACULTY')}>Faculty</li>
          </Link> */}
          <Link to="/" className="speaker">
            <li onClick={() => scrollToSection('CONTACT')}>Contact</li>
          </Link>
          <Link to="/team" className="speaker">
            <li>Developers</li>
          </Link>
          <Link to="/feedback" className="faculty">
            <li >Feedback</li>
          </Link>
          <Link to="/register" className={isMobile? "reg-mobile":"register"}>
            <li>Register</li>
          </Link>
        </ul>
        <button className='mobile-menu-icon' onClick={()=> setIsMobile(!isMobile)}>
          {isMobile? <i className="fas fa-times"></i> : <i className='fas fa-bars'></i>}
        </button>

        </nav>
      
  );
};

export default Navbar;
