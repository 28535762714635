import React from "react";
function FooterDev() {
  return (
    <>
    <div className="footer_sec">
      <div className="footer_main">
        <div className="footer_heading">Made with ❤️ by Team Conatus</div>
      </div>
      </div>
    </>
  );
}

export default FooterDev;
